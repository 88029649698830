import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = 'ff56c7-70';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  defaultViewMode: 'grid',
  pagination: {
    centralRange: 0,
    edgeRange: 1,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'SearchHeader'].includes(w.name),
    ),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      pageSizes: [12, 18, 36, 48],
      pageSizeSelectClass: 'form-select',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
      fields:
        !!window.Convermax.config?.extraFieldsAsFilters && shopifyGenericDefaults.fitmentSearch.baseFields,
    },
    {
      name: 'VehicleInfo',
      initCollapsed: true,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'AutoSyncVisualization',
      apiKey: AutoSyncKey,
      config: {
        height: '150px',
        vehicleImageSize: 640,
        vehicleAngles: [32],
        showColorPicker: false,
      },
      classModificator: 'search-result',
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
